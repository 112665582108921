<template>
  <div class="orderManger_list">
    <GlobalInfoBar title="订单列表" />
    <GlobalForm
      :init-data="initData"
      :form-item-list="formItemList"
      :inline="true"
      round
      @handleConfirm="handleConfirm"
      confirmBtnName="查询"
    >
      <el-form-item
        class="el_from"
        label="卡号卡密:"
        slot="cardOperation"
        v-if="this.cardStatus"
      >
        <el-input
          v-model="card"
          @input="cardSecretInput"
          placeholder="请输入卡号卡密查询"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item
        class="el_from"
        label="订单类型:"
        slot="operation"
        v-if="!this.moldStatus"
      >
        <el-select
          v-model="mold"
          placeholder="请选择订单类型"
          clearable
          @change="orderStatusClick2"
        >
          <el-option
            v-for="item in moldList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="el_from" label="订单状态:" slot="operation">
        <el-select
          v-model="orderStatus"
          placeholder="请选择订单状态"
          clearable
          @change="orderStatusClick"
        >
          <el-option
            v-for="item in statusList"
            :key="item.key"
            :label="item.name"
            :value="item.key"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="el_from" label="所属商家:" slot="operation">
        <el-select
          v-model="group_id"
          placeholder="请选择所属商家"
          clearable
          filterable
          @change="selectClick"
        >
          <el-option
            v-for="item in selsctList"
            :key="item.group_id"
            :label="item.group_name"
            :value="item.group_id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="el_from" label="推送状态:" slot="push_state">
        <el-select
          v-model="push_state"
          placeholder="请选择推送状态"
          clearable
          @change="pushStateChange"
        >
          <el-option
            v-for="item in [
              { label: '未推送', id: 0 },
              { label: '已推送', id: 1 },
            ]"
            :key="item.id"
            :label="item.label"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="el_from" label="回调状态:" slot="callback_state">
        <el-select
          v-model="callback_state"
          placeholder="请选择回调状态"
          clearable
          @change="callbackStateChange"
        >
          <el-option
            v-for="item in [
              { label: '未回调', id: 0 },
              { label: '已回调', id: 1 },
            ]"
            :key="item.id"
            :label="item.label"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <div slot="AddSlot">
        <el-button
          size="small"
          @click="$router.push('/systemManage/orderLog')"
          type="primary"
          icon="el-icon-plus"
          >查看订单日志</el-button
        >
      </div>
    </GlobalForm>
    <GlobalTable
      ref="GlobalTable"
      :columns="tableColumns"
      :data="tableData"
      :currentPage="page"
      :total="total"
      @handleCurrentChange="handleCurrentChange"
    >
      <el-table-column label="订单类型" slot="operatAddress" align="center">
        <template slot-scope="{ row }">
          <div class="send_color">
            <div>{{ row.mold_name }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="补交类型" slot="relation_type" align="center">
        <template slot-scope="{ row }">
          <div class="send_color">
            <div>
              {{
                ["--", "权益单", "理赔单", "维修单", "换机完成单"][
                  row.relation_type
                ]
              }}
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="订单编号"
        slot="order_sn"
        align="center"
        width="150px"
      >
        <template slot-scope="{ row }">
          <div v-if="row.two == 1" class="tg biaoqian">多险种同购</div>
          <div v-else-if="row.choose == 1" class="fs biaoqian">延保附属购</div>
          <span
            class="mark"
            v-if="row.uuid == 1 || row.uuid == 2"
            :style="{ background: ['#0981FF'][row.uuid - 1] }"
            >{{ ["卖", "英", "九"][row.uuid - 1] }}</span
          ><span
            class="mark"
            v-else-if="row.uuid == 3"
            style="background: #0981ff"
            >九</span
          >{{ row.order_sn }}
        </template>
      </el-table-column>
      <el-table-column
        label="顾客姓名"
        slot="name"
        align="center"
        v-if="
          parseInt(mold) != 13 &&
          parseInt(mold) != 14 &&
          parseInt(mold) != 21 &&
          parseInt(mold) != 15 &&
          parseInt(mold) != 16 &&
          parseInt(mold) != 18 &&
          parseInt(mold) != 17
        "
      >
        <template slot-scope="{ row }">
          <div class="send_color">
            <div>{{ row.name }}</div>
          </div>
        </template>
      </el-table-column>

      <el-table-column
        label="推送状态"
        slot="push_state"
        align="center"
        v-if="
          parseInt(mold) <= 6 ||
          parseInt(mold) == 19 ||
          parseInt(mold) == 20 ||
          parseInt(mold) == 24 ||
          parseInt(mold) == 26 ||
          parseInt(mold) == 28 ||
          parseInt(mold) == 29 ||
          parseInt(mold) == 33 ||
          parseInt(mold) == 35 ||
          parseInt(mold) == 38
        "
      >
        <template slot-scope="{ row }">
          <span>
            {{ row && row.push_state === 1 ? "成功" : "-" }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        label="回调状态"
        slot="callback_state"
        align="center"
        v-if="
          parseInt(mold) <= 6 ||
          parseInt(mold) == 19 ||
          parseInt(mold) == 20 ||
          parseInt(mold) == 24 ||
          parseInt(mold) == 26 ||
          parseInt(mold) == 28 ||
          parseInt(mold) == 29 ||
          parseInt(mold) == 33 ||
          parseInt(mold) == 35 ||
          parseInt(mold) == 38
        "
      >
        <template slot-scope="{ row }">
          <span>
            {{ row && row.callback_state === 1 ? "成功" : "-" }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        label="保单号"
        slot="policyNo"
        align="center"
        v-if="
          parseInt(mold) <= 6 ||
          parseInt(mold) == 19 ||
          parseInt(mold) == 20 ||
          parseInt(mold) == 24 ||
          parseInt(mold) == 26 ||
          parseInt(mold) == 28 ||
          parseInt(mold) == 29 ||
          parseInt(mold) == 33 ||
          parseInt(mold) == 35 ||
          parseInt(mold) == 38
        "
      >
        <template slot-scope="{ row }">
          <span>
            {{ (row && row.policyNo) || "-" }}
          </span>
        </template>
      </el-table-column>

      <el-table-column
        label="跟进员工"
        slot="group_remark"
        align="center"
        v-if="orderType === 'register'"
      >
        <template slot-scope="{ row }">
          <span>
            {{ (row && row.group_remark) || "-" }}
          </span>
        </template>
      </el-table-column>

      <el-table-column label="审核状态" slot="verifyStatus" align="center">
        <template slot-scope="{ row }">
          <span>
            {{ row.exa_status }}
          </span>
        </template>
      </el-table-column>

      <el-table-column width="200" label="操作" slot="operating" align="center">
        <template slot-scope="{ row }">
          <el-button type="text" @click="handleBtn(row)">查看详情</el-button>
          <el-button
            v-if="
              parseInt(mold) == 4 ||
              parseInt(mold) == 5 ||
              parseInt(mold) == 28 ||
              parseInt(mold) == 1 ||
              parseInt(mold) == 29 ||
              parseInt(mold) == 33 || 
              parseInt(mold) == 35
            "
            type="text"
            @click="handleTag(row)"
            >{{ row.two == 0 ? "标记同购" : "取消同购" }}</el-button
          >
          <el-button type="text" @click="handleDeletr(row)" class="btn_delete"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </GlobalTable>
  </div>
</template>

<script>
import _api from "@/utils/request";
export default {
  name: "orderManger_list",
  data() {
    return {
      searchParams:{},//订单列表参数
      orderStatus: "", //订单审核状态
      limit: 10, //每页条数
      page: 1, //页码
      total: 0, //总条数
      beg_time: "", //开始时间
      end_time: "", //结束时间
      phone: "", //手机号
      imei: "", //imei查询
      card: "",
      mold: "", //订单类型
      group_id: "", //所属商家
      push_state: "",
      callback_state: "",
      policyNo: "",
      initData: null,
      formItemList: [
        {
          key: "phone",
          type: "input",
          labelName: "手机号码:",
          placeholder: "请输入手机号码",
        },
        { slotName: "cardOperation" },
        { slotName: "operation" },
        {
          key: "imei",
          type: "input",
          labelName: "IMEI查询:",
          placeholder: "请输入IMEI查询",
        },
        { slotName: "push_state" },
        { slotName: "callback_state" },

        // {
        //     key: 'card',
        //     type: 'input',
        //     labelName: '卡密查询:',
        //     placeholder: '请输入卡号卡密查询'
        // },
        // {
        //     key: 'group_id',
        //     type: 'select',
        //     labelName: '所属商家:',
        //     placeholder: '请输入所属商家',
        //     option:[]
        // },
        {
          key: "policyNo",
          type: "input",
          labelName: "保单号:",
          placeholder: "请输入保单号查询",
        },
        {
          key: "entryTime",
          type: "daterange",
          labelName: "上传时间:",
          placeholder: "请选择上传时间",
          valueFormat: "timestamp",
        },
      ],
      orderType: "",
      // 表格
      tableData: [],
      selsctList: [], //所属哦商家下拉数组
      statusList: [], //订单状态下拉数组
      moldList: [], //mold
      moldStatus: false,
      cardStatus: false,
      tableColumns: [
        // { label: "订单编号", prop: "order_sn" },
        { slotName: "order_sn" },
        { slotName: "operatAddress" },
        { label: "订单手机号", prop: "phone" },
        { label: "服务选择", prop: "finish_type" },
        { label: "操作人", prop: "admin_user_name" },
        { label: "所属商家", prop: "group_name" },
        { slotName: "name" },
        { label: "IMEI", prop: "imei" },
        { label: "上传时间", prop: "add_time", width: "150px" },
        { label: "审核时间", prop: "exa_time", width: "150px" },
        { slotName: "push_state" },
        { slotName: "callback_state" },
        { slotName: "policyNo" },
        { slotName: "group_remark" },
        { slotName: "verifyStatus" },
        { slotName: "operating" },
      ],
    };
  },
  created(){
    if(sessionStorage.getItem('orderDetail')){
      this.searchParams = JSON.parse(sessionStorage.getItem('orderDetail'))
      sessionStorage.removeItem('orderDetail')
      this.limit = this.searchParams.limit
      this.page = this.searchParams.page
      this.phone = this.searchParams.phone
      this.card = this.searchParams.card
      this.imei = this.searchParams.imei
      this.group_id = this.searchParams.group_id
      this.mold = this.searchParams.mold
      this.beg_time = this.searchParams.beg_time
      this.end_time = this.searchParams.end_time
      this.exa_status = this.searchParams.exa_status
      this.push_state = this.searchParams.push_state
      this.callback_state = this.searchParams.callback_state
      this.policyNo = this.searchParams.policyNo
      this.orderList();
    }else{
      this.searchParams = null
    }
  },
  mounted() {
    let query = this.$route.query;

    this.initData = {
      phone: query.phone || "",
      imei: query.imei || "",
      policyNo: query.policyNo || "",
      entryTime: query.entryTime && query.entryTime.split(","),
    };

    this.phone = query.phone || "";
    this.imei = query.imei || "";
    this.card = query.card || "";
    this.mold = query.mold || "";
    if (query.push_state) {
      this.push_state = parseInt(query.push_state);
    }
    if (query.callback_state) {
      this.callback_state = parseInt(query.callback_state);
    }
    if (query.group_id) {
      this.group_id = parseInt(query.group_id);
    }
    if (query.orderStatus) {
      this.orderStatus = parseInt(query.orderStatus);
    }
    console.log("queryqueryqueryqueryqueryquery", query);
    if (query.entryTime) {
      let split = query.entryTime.split(",") || [];
      this.beg_time = split[0] || "";
      this.end_time = split[1] || "";
    }

    this.moldStatus = false;
    this.cardStatus = true;
    if (query.mold) {
      this.moldStatus = true;
      console.log(query, "item");
      // this.orderStatus = 0
      this.mold = query.mold;
      if (query.mold === "13") {
        this.selectRepairList();
      } else {
        this.selectGroupList();
      }
      if (query.mold === "7" || query.mold === "10") {
        this.tableColumns.splice(2, 0, {
          label: "服务选择",
          prop: "ins_service_name",
        });
      }

      if (query.mold === "21") {
        this.tableColumns.splice(2, 0, { slotName: "relation_type" });
      }

      let orderTypeInfo = require(`../detail/orderType${query.mold}`);
      this.orderType = orderTypeInfo.type;

      this.orderList();
    } else {
      console.log(this.moldStatus, "this.moldStatuthis.moldStatu");
      this.selectMoldList();
    }
    this.selectGroupList();
    this.selectStutasList();
  },
  methods: {
    //合作商下拉
    selectGroupList() {
      _api.selectGroupList().then((res) => {
        console.log(res);
        this.selsctList = res.data;
      });
    },
    //维修商下拉
    selectRepairList() {
      _api.selectRepairList().then((res) => {
        console.log(res);
        this.selsctList = res.data;
      });
    },
    //订单状态
    selectStutasList() {
      _api.orderAuditStatus().then((res) => {
        console.log(res, "orderAuditStatus");
        this.statusList = res.data;
      });
    },
    // mold
    selectMoldList() {
      _api.orderMold().then((res) => {
        console.log(res, "orderMold");
        this.moldList = res.data;
        this.mold = res.data[0].id;
        if (
          this.mold == 1 ||
          this.mold == 2 ||
          this.mold == 3 ||
          this.mold == 4 ||
          this.mold == 5 ||
          this.mold == 6 
        ) {
          this.cardStatus = true;
        } else {
          this.cardStatus = false;
        }
        this.orderList();
      });
    },
    selectClick(val) {
      this.group_id = val;
      this.setQuery("group_id", this.group_id);
    },
    pushStateChange(val) {
      this.push_state = val;
      this.setQuery("push_state", this.push_state);
    },
    callbackStateChange(val) {
      this.callback_state = val;
      this.setQuery("callback_state", this.callback_state);
    },
    orderStatusClick(val) {
      console.log(val, "orderStatusClick");
      this.orderStatus = val;

      this.setQuery("orderStatus", this.orderStatus);
    },
    orderStatusClick2(val) {
      console.log(val, "orderStatusClick2");
      this.mold = val;
      if (
        val == 1 ||
        val == 2 ||
        val == 3 ||
        val == 4 ||
        val == 5 ||
        val == 6
      ) {
        this.cardStatus = true;
      } else {
        this.cardStatus = false;
      }

      this.setQuery("mold", this.mold);
    },
    // 分页
    handleCurrentChange(val) {
      console.log(val);
      this.page = val;
      this.orderList();
    },
    orderList() {
      const SeachParams = {
        limit: this.limit,
        page: this.page,
        phone: this.phone,
        card: this.card,
        imei: this.imei,
        group_id: this.group_id,
        mold: this.mold,
        beg_time: this.beg_time,
        end_time: this.end_time,
        exa_status: this.orderStatus,
        push_state: this.push_state,
        callback_state: this.callback_state,
        policyNo: this.policyNo,
      };
      this.searchParams=SeachParams
      _api.orderList(SeachParams).then((res) => {
        this.tableData = res.data;
        this.total = res.count;
      });
    },
    handleTag(row) {
      console.log(row.two);
      _api
        .markOrderTwo({ order_id: row.order_id, two: row.two == 0 ? 1 : 0 })
        .then((res) => {
          this.orderList();
        });
    },
    handleConfirm(data, cd) {
      cd();
      console.log("提交了form", data);
      console.log("提交了form", this.card);
      this.page = 1;
      if (data) {
        this.phone = data.phone;
        this.imei = data.imei;
        this.policyNo = data.policyNo;

        if (data.entryTime === null) {
          (this.beg_time = ""), (this.end_time = "");
        } else {
          this.beg_time = (data.entryTime && data.entryTime[0]) || "";
          this.end_time = (data.entryTime && data.entryTime[1]) || "";
        }
      } else {
        (this.beg_time = ""), (this.end_time = "");
      }
      const SeachParams = {
        limit: this.limit,
        page: this.page,
        phone: this.phone,
        card: this.card,
        imei: this.imei,
        group_id: this.group_id,
        mold: this.mold,
        beg_time: this.beg_time,
        end_time: this.end_time,
        exa_status: this.orderStatus,
        push_state: this.push_state,
        callback_state: this.callback_state,
        policyNo: this.policyNo,
      };
      _api.orderList(SeachParams).then((res) => {
        this.tableData = res.data;
        this.total = res.count;
      });

      const query = JSON.parse(JSON.stringify(this.$route.query));
      query.phone = data.phone;
      query.imei = data.imei;
      query.policyNo = data.policyNo;
      query.entryTime =
        (data.entryTime && data.entryTime.length && data.entryTime.join(",")) ||
        "";
      this.$router.push({ path: this.$route.path, query });
    },
    //详情
    handleBtn(row) {
      sessionStorage.setItem('orderDetail',JSON.stringify(this.searchParams))
      this.$router.push({
        name: "订单详情",
        params: { type: row.mold, orderId: row.order_id },
      });
    },
    handleDeletr(row) {
      this.$confirm("删除后数据不可恢复，确认删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          _api
            .delOrder({ mold: this.mold, order_id: row.order_id })
            .then((res) => {
              if (res.code === 1) {
                this.$message.success(res.msg);
                this.orderList();
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    setQuery(key, value) {
      const query = JSON.parse(JSON.stringify(this.$route.query));
      query[key] = value;
      this.$router.push({ path: this.$route.path, query });
    },
    cardSecretInput() {
      this.setQuery("card", this.card);
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.orderManger_list {
  /deep/ .el-form-item__label {
    width: 80px !important;
  }
  .btn_delete {
    color: #ff687b;
  }
}
.mark {
  font-size: 12px;
  //background: red;
  color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 2px;
  width: 14px;
  height: 14px;
  line-height: 14px;
  text-align: center;
}
.biaoqian {
  position: absolute;
  left: 0;
  top: 0;
  padding: 0 5px;
  border-radius: 5px;
  box-sizing: border-box;
  color: white;
}
.tg {
  background: orange;
}
.fs {
  background: rgb(0, 195, 255);
}
</style>
